"use client";

import { motion, AnimatePresence } from "framer-motion";

import { useQueryState } from "@/stores/query-state";

import StationDetails from "@/app/sidebar/station-details";
import LocationDetails from "@/app/sidebar/location-details";
import RouteDetails from "@/app/sidebar/route-details";

function SideBar() {
  const {
    selectedLocation,
    selectedStation,
    departureLocation,
    destinationLocation,
  } = useQueryState();

  return (
    <AnimatePresence mode="popLayout" initial={false}>
      {selectedStation ? (
        <motion.main
          key="station"
          initial={{ transform: "translateX(-50%)", opacity: 0 }}
          animate={{ transform: "translateX(0)", opacity: 1 }}
          exit={{ transform: "translateX(-50%)", opacity: 0 }}
        >
          <StationDetails className="p-2 md:p-4" station={selectedStation} />
        </motion.main>
      ) : selectedLocation ? (
        <motion.main
          key="location"
          initial={{ transform: "translateX(-50%)", opacity: 0 }}
          animate={{ transform: "translateX(0)", opacity: 1 }}
          exit={{ transform: "translateX(-50%)", opacity: 0 }}
        >
          <LocationDetails
            className="p-2 md:px-4"
            location={selectedLocation}
          />
        </motion.main>
      ) : departureLocation && destinationLocation ? (
        <motion.main
          key="station"
          initial={{ transform: "translateX(-50%)", opacity: 0 }}
          animate={{ transform: "translateX(0)", opacity: 1 }}
          exit={{ transform: "translateX(-50%)", opacity: 0 }}
        >
          <RouteDetails
            className="p-2 md:p-4"
            departureLocation={departureLocation}
            destinationLocation={destinationLocation}
          />
        </motion.main>
      ) : (
        <motion.main
          key="no-station"
          className="flex grow p-4 pt-8 md:items-center md:pt-0"
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
        >
          <div className="flex flex-col gap-2">
            <h1 className="text-xl font-bold tracking-tight">
              Vergelijk. Bespaar. Tanken maar!
            </h1>
            <p className="text-muted-foreground">
              Zoek een locatie op de kaart om tankstations in de buurt te zien.
            </p>
          </div>
        </motion.main>
      )}
    </AnimatePresence>
  );
}

export default SideBar;
