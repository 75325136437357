import { FuelType } from "@/types";

type FuelBadgeProps = {
  fuelType: FuelType;
  size?: "sm" | "md";
};

const fuelTypeStyles = {
  euro95: "bg-white text-emerald-700",
  euro98: "bg-cyan-600 text-white",
  diesel: "bg-yellow-400 text-black",
};

const badgeSizeStyles = {
  sm: "px-2 py-1 text-xs",
  md: "px-3 py-2 text-base",
};

export default function FuelBadge({ fuelType, size = "sm" }: FuelBadgeProps) {
  const fuelTypeLabel = fuelType.charAt(0).toUpperCase() + fuelType.slice(1);

  return (
    <span
      className={`rounded-md font-semibold ${fuelTypeStyles[fuelType]} ${badgeSizeStyles[size]}`}
    >
      {fuelTypeLabel}
    </span>
  );
}
