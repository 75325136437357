import Link from "next/link";

import { usePathname } from "next/navigation";

import { ChevronDownIcon } from "@radix-ui/react-icons";

import {
  DropdownMenu,
  DropdownMenuCheckboxItem,
  DropdownMenuContent,
  DropdownMenuLabel,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from "@/components/ui/dropdown-menu";

import { FUEL_TYPES } from "@/types";

import { cn } from "@/lib/utils";

import { useAppStore } from "@/stores/app-state-provider";

import LogoImage from "@/components/logo-image";
import FuelBadge from "@/components/fuel-badge";

function Header() {
  const { selectedFuelType, setSelectedFuelType } = useAppStore(
    (state) => state,
  );

  const currentPath = usePathname();
  return (
    <header className="flex min-h-14 w-full items-center border-b border-border px-4 md:pl-6">
      <Link href="/" className="flex h-6 items-center gap-2 text-primary">
        <LogoImage className="size-5 fill-primary md:size-full" />
        <span className="-mt-1 inline-flex text-lg font-bold leading-none md:text-xl">
          pompwijzer
        </span>
      </Link>
      <div className="ml-auto flex items-center gap-4">
        <nav className="flex items-center border-r border-border pr-4 text-sm text-muted-foreground hover:text-foreground md:ml-6">
          <Link
            href="/stats"
            className={cn(currentPath === "/stats" && "text-foreground")}
          >
            Stats
          </Link>
        </nav>
        <DropdownMenu>
          <DropdownMenuTrigger className="flex items-center gap-1 rounded-lg bg-secondary pr-1">
            <FuelBadge fuelType={selectedFuelType} />
            <ChevronDownIcon className="size-4" />
          </DropdownMenuTrigger>
          <DropdownMenuContent align="end" className="min-w-[6rem]">
            <DropdownMenuLabel>Brandstof</DropdownMenuLabel>
            <DropdownMenuSeparator />
            {Object.values(FUEL_TYPES).map((fuelType) => (
              <DropdownMenuCheckboxItem
                key={fuelType}
                checked={selectedFuelType === fuelType}
                onSelect={() => setSelectedFuelType(fuelType)}
              >
                <FuelBadge fuelType={fuelType} />
              </DropdownMenuCheckboxItem>
            ))}
          </DropdownMenuContent>
        </DropdownMenu>
      </div>
    </header>
  );
}

export default Header;
