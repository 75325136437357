"use client";

import { useEffect, useState } from "react";

import { CookieIcon } from "lucide-react";

import { GoogleTagManager } from "@next/third-parties/google";

import { Button } from "@/components/ui/button";

import { cn } from "@/lib/utils";

function Analytics() {
  return <GoogleTagManager gtmId="GTM-5TX5S2SH" />;
}

export default function AnalyticsConsent() {
  const [isOpen, setIsOpen] = useState(false);
  const [hide, setHide] = useState(false);
  const [consent, setConsent] = useState<"accepted" | "declined" | null>(null);

  const accept = () => {
    setIsOpen(false);
    document.cookie =
      "cookieConsent=true; expires=Fri, 31 Dec 9999 23:59:59 GMT";
    setTimeout(() => {
      setHide(true);
      setConsent("accepted");
    }, 700);
  };

  const decline = () => {
    setIsOpen(false);
    document.cookie =
      "cookieConsent=false; expires=Fri, 31 Dec 9999 23:59:59 GMT";
    setTimeout(() => {
      setHide(true);
      setConsent("declined");
    }, 700);
  };

  useEffect(() => {
    if (document.cookie.includes("cookieConsent=true")) {
      setConsent("accepted");
    } else if (document.cookie.includes("cookieConsent=false")) {
      setConsent("declined");
    } else {
      setIsOpen(true);
    }
  }, []);

  if (consent === "accepted") {
    return <Analytics />;
  }

  return (
    <div
      className={cn(
        "fixed bottom-0 left-0 right-0 z-[200] w-full duration-700 sm:bottom-4 sm:left-4 sm:max-w-md",
        !isOpen
          ? "translate-y-8 opacity-0 transition-[opacity,transform]"
          : "translate-y-0 opacity-100 transition-[opacity,transform]",
        hide && "hidden",
      )}
    >
      <div className="m-3 rounded-lg border border-border bg-background dark:bg-card">
        <div className="flex items-center justify-between p-3">
          <h1 className="text-lg font-medium">Wij gebruiken cookies</h1>
          <CookieIcon className="h-[1.2rem] w-[1.2rem]" />
        </div>
        <div className="-mt-2 p-3">
          <p className="text-left text-sm text-muted-foreground">
            Wij gebruiken cookies op deze site om de gebruikerservaring te
            verbeteren. Door op de accepteer knop te klikken, sta je ons toe om
            dit te doen.
          </p>
        </div>
        <div className="mt-2 flex items-center gap-2 border-t p-3">
          <Button onClick={accept} className="h-9 w-full rounded-full">
            Accepteren
          </Button>
          <Button
            onClick={decline}
            className="h-9 w-full rounded-full"
            variant="secondary"
          >
            Nee, bedankt
          </Button>
        </div>
      </div>
    </div>
  );
}
