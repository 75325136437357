import { useQuery } from "@tanstack/react-query";

import { useQueryState } from "@/stores/query-state";

import { useAppStore } from "@/stores/app-state-provider";

import {
  GasPriceSchema,
  GasPricesSchema,
  StationDetailsSchema,
  StationsSchema,
} from "@/types";
import type { GasPrice, GasPrices, StationDetails, Stations } from "@/types";

const API_URL =
  process.env.NODE_ENV === "production"
    ? "https://api.pompwijzer.nl/api"
    : "/api";

export function useStations() {
  const selectedFuelType = useAppStore((state) => state.selectedFuelType);

  return useQuery<Stations>({
    queryKey: [selectedFuelType, "stations"],
    queryFn: async () => {
      const response = await fetch(
        `${API_URL}/stations?fuel_type=${selectedFuelType}`,
      );
      return StationsSchema.parse(await response.json());
    },
  });
}

export function useSelectedStationDetails() {
  const selectedFuelType = useAppStore((state) => state.selectedFuelType);
  const { selectedStation } = useQueryState();
  const stationId = selectedStation?.id;

  return useQuery<StationDetails>({
    queryKey: [selectedFuelType, "stations", stationId],
    queryFn: async () => {
      const response = await fetch(
        `${API_URL}/stations/${stationId}?fuel_type=${selectedFuelType}`,
      );
      return StationDetailsSchema.parse(await response.json());
    },
    enabled: !!stationId,
  });
}

export function useStationsAveragePrice() {
  const selectedFuelType = useAppStore((state) => state.selectedFuelType);

  return useQuery<GasPrice>({
    queryKey: [selectedFuelType, "prices", "average"],
    queryFn: async () => {
      const response = await fetch(
        `${API_URL}/prices/average?fuel_type=${selectedFuelType}`,
      );
      return GasPriceSchema.parse(await response.json());
    },
  });
}

export function useStationsAveragePrices(period: "day" | "month") {
  const selectedFuelType = useAppStore((state) => state.selectedFuelType);

  return useQuery<GasPrices>({
    queryKey: [selectedFuelType, "prices", "average", period],
    queryFn: async () => {
      const response = await fetch(
        `${API_URL}/prices/average?fuel_type=${selectedFuelType}&period=${period}`,
      );
      return GasPricesSchema.parse(await response.json());
    },
  });
}
