import { cn } from "@/lib/utils";

type FooterProps = {
  className?: string;
};

function Footer({ className }: FooterProps) {
  return (
    <footer
      className={cn(
        "flex w-full items-center justify-center py-4 text-xs text-zinc-700 md:py-2",
        className,
      )}
    >
      © {new Date().getFullYear()}, pompwijzer.nl
    </footer>
  );
}

export default Footer;
