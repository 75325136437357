import { z } from "zod";

export const FUEL_TYPES = ["euro95", "euro98", "diesel"] as const;
export const FuelTypeSchema = z.enum(FUEL_TYPES);
export type FuelType = z.infer<typeof FuelTypeSchema>;

export const GasPriceWithStatsSchema = z.object({
  fuel_type: FuelTypeSchema,
  price: z.number().optional(),
  is_below_average: z.boolean().optional(),
  timestamp: z.string().datetime().pipe(z.coerce.date()),
});
export type GasPriceWithStats = z.infer<typeof GasPriceWithStatsSchema>;

export const StationSchema = z
  .object({
    id: z.number(),
    name: z.string().optional(),
    brand: z.string().optional(),
    address: z.string(),
    postal_code: z.string(),
    city: z.string(),
    country: z.string(),
    longitude: z.number(),
    latitude: z.number(),
    price: GasPriceWithStatsSchema.optional(),
  })
  .transform((data) => ({
    ...data,
    full_name: `${data.brand ?? ""} ${data.name ?? ""}`.trim(),
  }));
export type Station = z.infer<typeof StationSchema>;

export const StationsSchema = z.array(StationSchema);
export type Stations = z.infer<typeof StationsSchema>;

export const GasPriceSchema = z.object({
  fuel_type: FuelTypeSchema,
  price: z.number().optional(),
  timestamp: z.string().datetime().pipe(z.coerce.date()),
});
export type GasPrice = z.infer<typeof GasPriceSchema>;

export const GasPricesSchema = z.array(GasPriceSchema);
export type GasPrices = z.infer<typeof GasPricesSchema>;

export const StationDetailsSchema = z
  .object({
    id: z.number(),
    name: z.string().optional(),
    brand: z.string().optional(),
    address: z.string(),
    postal_code: z.string(),
    city: z.string(),
    country: z.string(),
    longitude: z.number(),
    latitude: z.number(),
    prices_24_hours: GasPricesSchema,
    prices_30_days: GasPricesSchema,
  })
  .transform((data) => ({
    ...data,
    full_name: `${data.brand ?? ""} ${data.name ?? ""}`.trim(),
  }));
export type StationDetails = z.infer<typeof StationDetailsSchema>;

export const CoordinatesSchema = z.object({
  longitude: z.number(),
  latitude: z.number(),
});
export type Coordinates = z.infer<typeof CoordinatesSchema>;

export const LocationSchema = z.object({
  name: z.string(),
  longitude: z.number(),
  latitude: z.number(),
});
export type Location = z.infer<typeof LocationSchema>;
