import { useMemo } from "react";

import { circle, booleanPointInPolygon, distance, bbox } from "@turf/turf";

import { useQueryState } from "@/stores/query-state";
import { useStations } from "@/api";

export function useStationsInRadius() {
  const { data: stations, isLoading } = useStations();
  const { selectedLocation, searchRadius } = useQueryState();

  return useMemo(() => {
    if (!selectedLocation) {
      return {
        stationsInRadiusArea: {
          type: "FeatureCollection",
          features: [],
        },
        stationsInRadiusBounds: undefined,
        stationsInRadius: undefined,
      };
    }

    // Note to future self:
    //  I benchmarked this approach vs. calculating the distance for each station
    //  first then filtering (I even tried combining the 2 loops into 1 using flatMap).
    //  I found no difference in performance, so I went with the more readable version.
    const position = [selectedLocation.longitude, selectedLocation.latitude];
    const stationsInRadiusArea = circle(position, searchRadius);
    const stationsInRadiusBounds = bbox(stationsInRadiusArea);
    const stationsInRadius = stations
      ?.filter((station) =>
        booleanPointInPolygon(
          [station.longitude, station.latitude],
          stationsInRadiusArea,
        ),
      )
      .map((station) => ({
        station,
        distance: distance([station.longitude, station.latitude], position),
      }));

    return {
      stationsInRadiusArea,
      stationsInRadiusBounds,
      stationsInRadius,
      isLoading,
    };
  }, [searchRadius, stations, selectedLocation, isLoading]);
}
