import type { LayerProps } from "react-map-gl";

import tailwind from "@/lib/tailwind";

export const stationsLayer: LayerProps = {
  id: "stations",
  type: "symbol",
  filter: ["!", ["has", "point_count"]],
  paint: {
    "icon-color": [
      "case",
      ["boolean", ["get", "is_below_average"], true],
      tailwind.theme.colors.green[600],
      tailwind.theme.colors.rose[600],
    ],
  },
  layout: {
    "icon-image": "gas-station-sdf",
    "icon-size": 0.35,
    "icon-anchor": "top",
  },
};

export const stationsPricesLayer: LayerProps = {
  id: "stations-prices",
  type: "symbol",
  filter: ["!", ["has", "point_count"]],
  paint: {
    "text-color": tailwind.theme.colors.white,
  },
  layout: {
    "icon-image": [
      "case",
      ["boolean", ["get", "is_below_average"], true],
      "tooltip-green-600",
      "tooltip-rose-600",
    ],
    "icon-text-fit": "both",
    "icon-allow-overlap": true,
    "text-field": ["get", "price"],
    "text-anchor": "top",
    "text-font": ["DIN Offc Pro Bold", "Arial Unicode MS Bold"],
    "text-size": 14,
    "text-offset": [0, -1.75],
    "text-allow-overlap": true,
  },
};

export const stationsClusterCountLayer: LayerProps = {
  id: "stations-cluster-count",
  type: "symbol",
  filter: ["has", "point_count"],
  paint: {
    "text-color": tailwind.theme.colors.white,
  },
  layout: {
    "text-field": "{point_count_abbreviated}",
    "text-font": ["DIN Offc Pro Bold", "Arial Unicode MS Bold"],
    "text-size": 14,
    "text-allow-overlap": true,
  },
};

export const stationsClusterLayer: LayerProps = {
  id: "stations-cluster",
  type: "circle",
  filter: ["has", "point_count"],
  paint: {
    "circle-color": tailwind.theme.colors.zinc[900],
    "circle-opacity": 0.9,
    "circle-radius": [
      "step",
      ["get", "point_count"],
      14,
      10,
      16,
      100,
      18,
      1000,
      22,
    ],
  },
};

export const stationsInRadiusCircleLayer: LayerProps = {
  id: "stations-in-radius-circle",
  type: "fill",
  paint: {
    "fill-color": tailwind.theme.colors.zinc[600],
    "fill-opacity": 0.1,
  },
};

export const routeLayer: LayerProps = {
  id: "route",
  type: "line",
  layout: {
    "line-join": "round",
    "line-cap": "round",
  },
  paint: {
    "line-color": tailwind.theme.colors.zinc[900],
    "line-width": 5,
    "line-opacity": 0.8,
  },
};

export const routeSearchAreaLayer: LayerProps = {
  id: "route-search-area",
  type: "fill",
  paint: {
    "fill-color": tailwind.theme.colors.zinc[600],
    "fill-opacity": 0.1,
  },
};
