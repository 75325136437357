import { createStore } from "zustand/vanilla";
import { devtools, persist } from "zustand/middleware";

import { FuelType } from "@/types";

export type AppState = {
  selectedFuelType: FuelType;
};

export type AppActions = {
  setSelectedFuelType: (fuelType: FuelType) => void;
};

export type AppStore = AppState & AppActions;

export const defaultInitState: AppState = {
  selectedFuelType: "euro95",
};

export const createAppStore = (initState: AppState = defaultInitState) => {
  return createStore<AppStore>()(
    devtools(
      persist(
        (set) => ({
          ...initState,
          setSelectedFuelType: (fuelType: FuelType) =>
            set({ selectedFuelType: fuelType }),
        }),
        {
          name: "app-state",
        },
      ),
    ),
  );
};
